<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 45.87 65.07"
  >
    <g id="Calque_2"
       data-name="Calque 2"
    >
      <g id="grouper">
        <path class="cls-1"
              d="M43.37,42.13a20.44,20.44,0,1,1-40.87,0c0-22.35,20.44-39,20.44-39S43.37,18.5,43.37,42.13Z"
        /><path class="cls-2"
                d="M24.34,54c-7.47-.29-12.84-4.29-13.8-13.28"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1,
.cls-2 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
}
.cls-1 {
  stroke-width: 5px;
}
.cls-2 {
  stroke-linecap: round;
  stroke-width: 5.08px;
}
</style>
